export const defaultNS: string = 'common'
export const fallbackLng: string = 'en'
export const languages: string[] = [fallbackLng, 'de']
export const cookieName: string = "language-cookie"

export function getOptions(lng: string = fallbackLng, ns: string = defaultNS) {
  return {
    // debug: true,
    supportedLngs: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns
  }
}

export function getValidLanguage(lng?: string): string {
  if (lng == undefined) {
    return fallbackLng;
  }
  for (const language of languages) {
    if (language == lng) {
      return language;
    }
  }
  return fallbackLng;
}