import { useEffect, useState } from "react";
import { OrganizationModel } from "../layout/OrganizationSelect/OrganizationModel";
import { OrganizationSelectionRepository } from "../layout/OrganizationSelect/OrganizationSelectionRepository";
import { SelectedOrganizationChangedEvent } from "../layout/OrganizationSelect/SelectedOrganizationChangedEvent";

type Props = {
  onChange?: (
    oldValue: OrganizationModel | undefined,
    newValue: OrganizationModel | undefined
  ) => void;
};
export default function useCurrentOrganizationSelection(
  props?: Readonly<Props>
) {
  const [selectedOrganization, setSelectedOrganization] = useState<
    OrganizationModel | undefined
  >();

  useEffect(() => {
    addEventListener();
    selectedOrganizationIdChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addEventListener() {
    window.addEventListener(
      SelectedOrganizationChangedEvent.TYPE,
      handleOrganizationSelectionChangedEvent
    );
  }

  const handleOrganizationSelectionChangedEvent: EventListener = (
    event: Event
  ) => {
    selectedOrganizationIdChanged();
  };

  function selectedOrganizationIdChanged() {
    const oldSelectedOrganization = selectedOrganization;
    const newSelectedOrganization =
      OrganizationSelectionRepository.getSelectedOrganization() ?? undefined;
    setSelectedOrganization(newSelectedOrganization);

    props?.onChange?.(oldSelectedOrganization, newSelectedOrganization);
  }

  return {
    selectedOrganization: selectedOrganization,
    selectedOrganizationId: selectedOrganization?.id,
    selectedOrganizationName: selectedOrganization?.name,
    selectedOrganizationEmailAddress: selectedOrganization?.emailAddress,
  };
}
