"use client";
import AppIcon from "@/components/global/icons/AppIcon";
import Link from "next/link";
import { useState } from "react";
import { Variant } from "react-bootstrap/types";
import LoadingIndicator from "../FullScreenOverlay/LoadingIndicator";
import "./icon-link.css";

type Props = {
  id: string;
  icon: AppIcon;
  variant?: Variant;
  href?: string;
  onClick?: (e?: any) => any;
  tooltip?: string;
  showLoadingIndicatorOnClick?: boolean;
  hidden?: boolean;
  disabled?: boolean;
};
export default function IconLink(props: Readonly<Props>) {
  const [isLoading, setLoading] = useState<boolean>(false);

  const onClick = (e: any) => {
    if (props.showLoadingIndicatorOnClick) {
      setLoading(true);
    }
    if (props.onClick) {
      props.onClick(e);
    }
  };

  if (isLoading) {
    return <LoadingIndicator/>;
  }

  if (props.disabled) {
    return <span className="icon-link disabled">{props.icon}</span>;
  }

  const properties = {
    hidden: props.hidden,
    id: props.id,
    className: `my-2 my-sm-0 icon-link ${("icon-link-" + (props.variant || "primary"))}`,
    href: props.href ?? "",
    onClick: onClick,
    children: props.icon
  }

  if (props.href) {
    return <Link {...properties} />;
  }

  return <span {...properties} />;
}
