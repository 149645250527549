import { useRouter } from "next/navigation";
import { useState } from "react";
import { Props } from "./CompetitionSearchForm";

export default function useCompetitionSearchFormController(
  props: Readonly<Props>
) {
  const router = useRouter();
  const defaultFrom: string = `${new Date().getFullYear()}-01-01`;
  const defaultTill: string = `${new Date().getFullYear()}-12-31`;

  const [isLoading, setLoading] = useState<boolean>(false);

  const [from, setFrom] = useState<string>(props.filter?.from ?? defaultFrom);
  const [till, setTill] = useState<string>(props.filter?.till ?? defaultTill);
  const [competitionName, setCompetitionName] = useState<string>(
    props.filter?.name ?? ""
  );
  const [organizationName, setOrganizationName] = useState<string>(
    props.filter?.organizationName ?? ""
  );

  const getDefaultFromIfEmpty = (value: string): string => {
    if (value === "") {
      return defaultFrom;
    }
    return value;
  };

  const getDefaultTillIfEmpty = (value: string): string => {
    if (value === "") {
      return defaultTill;
    }
    return value;
  };

  const onSubmit = () => {
    setLoading(true);
    props.onSubmit?.();
  };

  const onReturn = () => {
    setLoading(false);
    props.onReturn?.();
  };

  const doSearch = () => {
    let actionUrl = props.searchAction ?? "";
    if (from !== defaultFrom || till !== defaultTill) {
      actionUrl = appendFilter(actionUrl, "from", from);
      actionUrl = appendFilter(actionUrl, "till", till);
    }
    actionUrl = appendFilter(actionUrl, "competitionName", competitionName);
    actionUrl = appendFilter(actionUrl, "organizationName", organizationName);

    router.push(actionUrl);
    onReturn();
  };

  const appendFilter = (
    currentUrl: string,
    key: string,
    value: string
  ): string => {
    if (value !== "") {
      if (currentUrl.lastIndexOf("?") === -1) {
        currentUrl += "?";
      } else {
        currentUrl += "&";
      }
      currentUrl += key;
      currentUrl += "=";
      currentUrl += value;
    }
    return currentUrl;
  };

  return {
    isLoading,
    getDefaultFromIfEmpty,
    getDefaultTillIfEmpty,
    setFrom,
    setTill,
    competitionName,
    setCompetitionName,
    organizationName,
    setOrganizationName,
    doSearch,
    onSubmit
  };
}
