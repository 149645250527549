import { config, library } from '@fortawesome/fontawesome-svg-core';
import { faAddressCard, faArrowLeftLong, faBell, faCalendar, faCalendarPlus, faCalendarXmark, faClone, faCopy, faEdit, faEnvelopeOpen, faEye, faFileCircleMinus, faFileCirclePlus, faFileDownload, faFileExport, faFileImport, faFont, faHand, faHouse, faIcons, faInfo, faLink, faLinkSlash, faList, faLock, faMagnifyingGlass, faMedal, faPeopleRoof, faPlus, faPowerOff, faQuestionCircle, faSave, faStopwatch, faToolbox, faTrash, faUnlock, faUpload, faUser, faUserEdit, faUserLock, faUserPlus, faUsers, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppIcon from "./AppIcon";

// styles.css wird in layout.tsx importiert, da sonst die styles doppelt geladen werden.
// import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

library.add(faQuestionCircle, faUser, faPowerOff, faTrash, faPlus, faArrowLeftLong, faX, faSave, faEdit,
  faFileCirclePlus, faFileCircleMinus, faLink, faLinkSlash, faCalendarPlus, faCalendarXmark, faCalendar, faUserLock,
  faUserPlus, faUsers, faClone, faBell, faHand, faInfo, faAddressCard, faEnvelopeOpen, faUserEdit, faStopwatch,
  faFileImport, faFileExport, faUnlock, faLock, faPeopleRoof, faMagnifyingGlass, faToolbox, faList, faUpload,
  faStopwatch, faMedal, faCopy, faIcons, faFont, faFileDownload, faFileImport, faHouse, faEye);

export namespace AppIcons {
  export const UserIcon = (<FontAwesomeIcon icon={"user"}/>) as AppIcon;
  export const PowerOffIcon = (<FontAwesomeIcon icon={"power-off"}/>) as AppIcon;
  export const CircleQuestionIcon = (<FontAwesomeIcon icon={"question-circle"}/>) as AppIcon;
  export const TrashIcon = (<FontAwesomeIcon icon={"trash"}/>) as AppIcon;
  export const PlusIcon = (<FontAwesomeIcon icon={"plus"}/>) as AppIcon;
  export const Back = (<FontAwesomeIcon icon={"arrow-left-long"}/>) as AppIcon;
  export const Cancel = (<FontAwesomeIcon icon={"x"}/>) as AppIcon;
  export const EditIcon = (<FontAwesomeIcon icon={"edit"}/>) as AppIcon;
  export const SaveIcon = (<FontAwesomeIcon icon={"save"}/>) as AppIcon;
  export const CreateCompetition = (<FontAwesomeIcon icon={"calendar-plus"}/>) as AppIcon;
  export const RemoveCompetition = (<FontAwesomeIcon icon={"trash"}/>) as AppIcon;
  export const AddLink = (<FontAwesomeIcon icon={"link"}/>) as AppIcon;
  export const RemoveLink = (<FontAwesomeIcon icon={"link-slash"}/>) as AppIcon;
  export const AddDocument = (<FontAwesomeIcon icon={"file-circle-plus"}/>) as AppIcon;
  export const RemoveDocument = (<FontAwesomeIcon icon={"file-circle-minus"}/>) as AppIcon;
  export const RemovePermission = (<FontAwesomeIcon icon={"trash"}/>) as AppIcon;
  export const RequestCompetitionPermission = (<FontAwesomeIcon icon={"user-lock"}/>) as AppIcon;
  export const RequestPermission = (<FontAwesomeIcon icon={"unlock"}/>) as AppIcon;
  export const Permissions = (<FontAwesomeIcon icon={"users"}/>) as AppIcon;
  export const CreateRegistrableCompetition = (<FontAwesomeIcon icon={"address-card"}/>) as AppIcon;
  export const GoToRegistrableCompetition = (<FontAwesomeIcon icon={"address-card"}/>) as AppIcon;
  export const CreateRegistration = (<FontAwesomeIcon icon={"envelope-open"}/>) as AppIcon;
  export const EditAthlete = (<FontAwesomeIcon icon={"user-edit"}/>)
  export const EditTimes = <FontAwesomeIcon icon={"stopwatch"}/>;
  export const DeleteAthlete = <FontAwesomeIcon icon={"trash"}/>;
  export const AddAthlete = <FontAwesomeIcon icon={"user-plus"}/>;
  export const AddAthleteSmall = <FontAwesomeIcon icon={"user-plus"}/>;
  export const AddTeam = <FontAwesomeIcon icon={"user-plus"}/>;
  export const EditTeam = <FontAwesomeIcon icon={"user-edit"}/>;
  export const EditTeamMembers = <FontAwesomeIcon icon={"users"}/>;
  export const DeleteTeam = <FontAwesomeIcon icon={"trash"}/>;
  export const ImportFile = <FontAwesomeIcon icon={"file-export"}/>;
  export const CloseRegistration = <FontAwesomeIcon icon={"lock"}/>;
  export const ReopenRegistration = <FontAwesomeIcon icon={"unlock"}/>;
  export const ValidateRegistration = <FontAwesomeIcon icon={"lock"}/>;
  export const RemoveRegistration = <FontAwesomeIcon icon={"trash"}/>;
  export const RemoveTeamMember = <FontAwesomeIcon icon={"x"}/>;
  export const Search = <FontAwesomeIcon icon={"magnifying-glass"}/>;
  export const Organization = <FontAwesomeIcon icon={"people-roof"}/>;
  export const Administration = <FontAwesomeIcon icon={"toolbox"}/>;
  export const RemoveRegistrableCompetition = <FontAwesomeIcon icon={"trash"}/>;
  export const ViewEventLogs = <FontAwesomeIcon icon={"list"}/>;
  export const Upload = <FontAwesomeIcon icon={"upload"}/>;
  export const Results = <FontAwesomeIcon icon={"stopwatch"}/>;
  export const AddMandatoryStatement = <FontAwesomeIcon icon={"plus"}/>;
  export const EditMandatoryStatement = <FontAwesomeIcon icon={"edit"}/>;
  export const AddMandatoryStatementLinkAttachment = <FontAwesomeIcon icon={"link"}/>;
  export const AddMandatoryStatementDocumentAttachment = (<FontAwesomeIcon icon={"file-circle-plus"}/>);
  export const RemoveMandatoryStatement = <FontAwesomeIcon icon={"trash"}/>;
  export const RemoveMandatoryStatementAttachment = <FontAwesomeIcon icon={"trash"}/>;
  export const Scoring = <FontAwesomeIcon icon={"medal"}/>;
  export const CopyAgeGroup = <FontAwesomeIcon icon={"copy"}/>;
  export const RemoveAgeGroup = <FontAwesomeIcon icon={"trash"}/>;
  export const AddDiscipline = <FontAwesomeIcon icon={"plus"}/>;
  export const RemoveDiscipline = <FontAwesomeIcon icon={"trash"}/>;
  export const AddCategory = <FontAwesomeIcon icon={"icons"}/>;
  export const EditCategory = <FontAwesomeIcon icon={"edit"}/>;
  export const RemoveCategory = <FontAwesomeIcon icon={"trash"}/>;
  export const AddEntry = <FontAwesomeIcon icon={"font"}/>;
  export const EditEntry = <FontAwesomeIcon icon={"edit"}/>;
  export const DeleteEntry = <FontAwesomeIcon icon={"trash"}/>;
  export const DownloadRuleset = <FontAwesomeIcon icon={"file-download"}/>;
  export const ImportRuleset = <FontAwesomeIcon icon={"file-import"}/>;
  export const Home = <FontAwesomeIcon icon={"house"}/>;
  export const View = <FontAwesomeIcon icon={"eye"}/>;
}
