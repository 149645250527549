import Button from "@/components/common/Button";
import FormGroupInput from "@/components/common/forms/FormGroupInput";
import { AppIcons } from "@/components/global/icons/AppIcons";
import { CompetitionFilter } from "@/lib/integration/features/Competitions/ViewCompetitions/CompetitionFilter";
import { TFunction } from "i18next";
import { Trans } from "react-i18next";
import useCompetitionSearchFormController from "./useCompetitionSearchFormController";

export type Props = {
  t: TFunction<string, undefined>;
  onSubmit?: () => void;
  onReturn?: () => void;
  searchAction?: string;
  filter?: CompetitionFilter;
};
export default function CompetitionSearchForm(props: Readonly<Props>) {
  const t = props.t;
  const controller = useCompetitionSearchFormController(props);

  return (
    <form action={controller.doSearch} onSubmit={controller.onSubmit}>
      <FormGroupInput
        inputId="competitionName"
        inputLabel={t("Name")}
        value={props.filter?.name}
        onChange={(event) => controller.setCompetitionName(event.target.value)}
      />
      <div className="row">
        <div className="col-md-6 col-12">
          <FormGroupInput
            inputId="from"
            inputLabel={t("FromDate")}
            inputType="date"
            value={props.filter?.from}
            onChange={(event) => controller.setFrom(controller.getDefaultFromIfEmpty(event.target.value))}
          />
        </div>
        <div className="col-md-6 col-12">
          <FormGroupInput
            inputId="till"
            inputLabel={t("TillDate")}
            inputType="date"
            value={props.filter?.till}
            onChange={(event) => controller.setTill(controller.getDefaultTillIfEmpty(event.target.value))}
          />
        </div>
      </div>
      <FormGroupInput
        inputId="organizationName"
        inputLabel={t("Organization")}
        value={props.filter?.organizationName}
        onChange={(event) => controller.setOrganizationName(event.target.value)}
      />
      <div className="form-group" hidden>
        <label htmlFor={"organization-filter"} className={"form-label"}>
          <Trans i18nKey="OrganizationFilter" t={t} />
        </label>
        <select
          id="organization-filter-type"
          name="organization-filter-type"
          className="form-select"
          disabled
          required
        >
          <option value="ConsiderOnlyThisOrganization">
            <Trans i18nKey="ConsiderOnlyThisOrganization" t={t} />
          </option>
          <option value="ConsiderSubOrganizations">
            <Trans i18nKey="ConsiderSubOrganizations" t={t} />
          </option>
          <option value="ConsiderHigherOrganizations">
            <Trans i18nKey="ConsiderHigherOrganizations" t={t} />
          </option>
        </select>
      </div>
      <div className="row justify-content-end">
        <div className="col-md-3 col-6 d-grid">
          <Button
            type="submit"
            id="search-competitions"
            icon={AppIcons.Search}
            style="subtle"
            showLoadingIndicator={controller.isLoading}
            disabled={controller.isLoading}
          >
            <Trans i18nKey="DoSearch" t={t} />
          </Button>
        </div>
        <div className="col-md-3 col-6 d-grid">
          <Button
            id="reset-search"
            type="reset"
            icon={AppIcons.Cancel}
            style="subtle"
          >
            <Trans i18nKey="Reset" t={t} />
          </Button>
        </div>
      </div>
    </form>
  );
}
