import { getValidLanguage } from "@/i18n/settings";

export default function useLocalizedDate(lng: string) {
  const language = getValidLanguage(lng);

  function isInPast(dateTime: string): boolean {
    const startDate = new Date(dateTime);
    const now = new Date(Date.now());
    return startDate.getTime() < now.getTime();
  }

  function getDaysTillDate(dateTime: string): number {
    const DAY_IN_MS = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const startDate = new Date(dateTime);
    const endDate = new Date(Date.now());
    const differenceInMs = startDate.getTime() - endDate.getTime();
    return Math.floor(differenceInMs / DAY_IN_MS);
  }

  function getHoursTillDate(dateTime: string): number {
    const HOUR_IN_MS = 60 * 60 * 1000; // Number of milliseconds in a day
    const startDate = new Date(dateTime);
    const endDate = new Date(Date.now());
    const differenceInMs = startDate.getTime() - endDate.getTime();
    return Math.floor(differenceInMs / HOUR_IN_MS);
  }

  function getMinutesTillDate(dateTime: string): number {
    const MINUTE_IN_MS = 60 * 1000; // Number of milliseconds in a day
    const startDate = new Date(dateTime);
    const endDate = new Date(Date.now());
    const differenceInMs = startDate.getTime() - endDate.getTime();
    return Math.floor(differenceInMs / MINUTE_IN_MS);
  }

  function LocalizedDateString(date: string) {
    return new Intl.DateTimeFormat(language, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(new Date(date));
  }

  function LocalizedTimeString(date: string) {
    return new Intl.DateTimeFormat(language, {
      hour: "2-digit",
      minute: "2-digit"
    }).format(new Date(date));
  }


  function LocalizedDateTimeString(dateTime: string) {
    return new Intl.DateTimeFormat(language, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(new Date(dateTime));
  }

  function LocalizedDateTimeRangeString(from: string, till?: string) {
    if (till && from !== till) {
      return `${LocalizedDateTimeString(from)} - ${LocalizedDateTimeString(
        till
      )}`;
    }
    return `${LocalizedDateTimeString(from)}`;
  }

  function LocalizedDateRangeString(from: string, till?: string) {
    if (till && from !== till) {
      return `${LocalizedDateString(from)} - ${LocalizedDateString(till)}`;
    }
    return `${LocalizedDateString(from)}`;
  }

  function DateTimeToLocalizedString(dateTime: Date) {
    return new Intl.DateTimeFormat(language, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(dateTime);
  }

  function DateToLocalizedString(date: Date) {
    "use client";
    return new Intl.DateTimeFormat(language, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(date);
  }

  function DateRangeToLocalizedString(from: Date, till?: Date | null) {
    "use client";
    if (till && from !== till) {
      return `${DateToLocalizedString(from)} - ${DateToLocalizedString(till)}`;
    }
    return `${DateToLocalizedString(from)}`;
  }

  return {
    LocalizedDateString,
    LocalizedTimeString,
    LocalizedDateTimeString,
    LocalizedDateRangeString,
    DateTimeToLocalizedString,
    DateToLocalizedString,
    DateRangeToLocalizedString,
    LocalizedDateTimeRangeString,
    getDaysTillDate,
    getHoursTillDate,
    getMinutesTillDate,
    isInPast
  };
}
