import AppIcon from "@/components/global/icons//AppIcon";
import Link from "next/link";
import { Variant } from "react-bootstrap/types";
import Button, { getButtonClass } from "./Button";
import "./LinkButton.css";
import React from "react";

type Props = {
  id: string,
  icon?: AppIcon,
  href?: string,
  iconPosition?: "left" | "right",
  variant?: Variant,
  style?: "default" | "subtle",
  onClick?: (e?: any) => any,
  hidden?: boolean,
  children?: React.ReactNode,
  disabled?: boolean
};
export default function LinkButton(props: Readonly<Props>) {
  const renderChildren = () => {
    return (
      <>
        {props.iconPosition !== "right" && (
          <span className="link-button-icon">{props.icon}</span>
        )}
        {props.children}
        {props.iconPosition === "right" && (
          <span className="link-button-icon">{props.icon}</span>
        )}
      </>
    );
  };

  if (!props.href) {
    return (
      <Button
        id={props.id}
        variant={props.variant}
        style={props.style}
        hidden={props.hidden}
        onClick={props.onClick}
        disabled={props.disabled || false}
      >
        {renderChildren()}
      </Button>
    );
  }

  return (
    <Link
      id={props.id}
      className={getButtonClass(props.variant, props.style, props.disabled || false)}
      href={props.disabled ? "" : props.href}
      hidden={props.hidden}
      onClick={props.disabled ? undefined : props.onClick}
    >
      {renderChildren()}
    </Link>
  );
}
