import AppIcon from "@/components/global/icons/AppIcon";
import React from "react";
import { ButtonVariant, Variant } from "react-bootstrap/types";
import "./Button.css";

export function getButtonClass(variant: Variant | undefined, style: "default" | "subtle" | undefined, disabled : boolean): string {
  const subClass = style === "subtle" ? "-outline" : "";
  const disabledClass = disabled ? " disabled" : "";
  return `btn btn${subClass}-${variant || "primary"}${disabledClass}`;
}

type Props = {
  id: string;
  showLoadingIndicator?: boolean;
  icon?: AppIcon;
  children?: React.ReactNode;
  onClick?: (e?: any) => any;
  variant?: ButtonVariant;
  type?: "submit" | "button" | "reset";
  name?: string;
  value?: string;
  iconPosition?: "left" | "right";
  hidden?: boolean;
  dismissBootstrapModal?: boolean;
  disabled?: boolean;
  className?: string;
  style?: "subtle" | "default";
};
export default function Button(props: Readonly<Props>) {
  const buttonClass = getButtonClass(props.variant, props.style, props.disabled || false);
  return (
    <button
      id={props.id}
      type={props.type || "button"}
      className={buttonClass}
      onClick={props.onClick}
      disabled={props.showLoadingIndicator || props.disabled}
      hidden={props.hidden}
      name={props.name}
      value={props.value}
      {...(props.dismissBootstrapModal && { "data-bs-dismiss": "modal" })}
    >
      {props.showLoadingIndicator && (
        <output
          className="spinner-border spinner-border-sm"
          aria-hidden="true"
        />
      )}
      {!props.showLoadingIndicator && (
        <>
          {props.iconPosition !== "right" && (
            <span className="button-icon">{props.icon}</span>
          )}
          {props.children}
          {props.iconPosition === "right" && (
            <span className="button-icon">{props.icon}</span>
          )}
        </>
      )}
    </button>
  );
}
