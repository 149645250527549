type Props = {
  hidden?: boolean;
};
export default function LoadingIndicator(props: Readonly<Props>) {
  return (
    <div className="spinner-border" role="status" hidden={props.hidden}>
      <span className="visually-hidden">Loading...</span>
    </div>
  );
}
