"use client";

import { Breadcrumbs } from "@/components/common/Breadcrumbs";
import useCard from "@/components/common/LabeledCard";
import LinkButton from "@/components/common/LinkButton";
import { useTranslation } from "@/i18n/client";
import { CompetitionFilter } from "@/lib/integration/features/Competitions/ViewCompetitions/CompetitionFilter";
import { Trans } from "react-i18next/TransWithoutContext";
import IconLink from "../../../common/IconLink/IconLink";
import { AppIcons } from "../../../global/icons/AppIcons";
import { CompetitionListItem } from "../../common/CompetitionOverviewTable/CompetitionListItem";
import CompetitionTable from "../../common/CompetitionOverviewTable/CompetitionOverviewTable";
import CompetitionSearchForm from "./CompetitionSearchForm";
import useCompetitionOverviewPageController from "./useCompetitionOverviewPageController";

export type Props = {
  lang: string;
  checkRightToCreateCompetitionFunction: (
    organizationId: string
  ) => Promise<boolean>;
  values?: CompetitionListItem[];
  filter?: CompetitionFilter;
};
export default function CompetitionOverviewPage(props: Readonly<Props>) {
  const controller = useCompetitionOverviewPageController(props);
  const { t } = useTranslation(props.lang, "competitions");

  const newCompetitionUrl = `/${props.lang}/competitions/create`;

  const breadcrumbs = [
    { label: t("Competitions"), url: `/${props.lang}/competitions` },
  ];

  const onSearchResult = () => {
    searchCard.setVisible(false);
    controller.setLoading(false);
  };

  const searchCard = useCard({
    title: t("Search"),
    id: "search-competition",
    closeable: true,
    initialVisible: false,
    children: (
      <CompetitionSearchForm
        t={t}
        searchAction={`/${props.lang}/competitions`}
        filter={props.filter}
        onSubmit={() => controller.setLoading(true)}
        onReturn={onSearchResult}
      />
    ),
  });

  return (
    <div className="container">
      <Breadcrumbs items={breadcrumbs}/>
      <div className="row align-items-center">
        <div className={"col-10"}>
          <h1>
            <Trans i18nKey="Competitions" t={t}/>
          </h1>
        </div>
        <div className="col-2 icon-container">
          <IconLink
            hidden={!controller.isCreateCompetitionEnabled}
            id="new-competition"
            icon={AppIcons.CreateCompetition}
            href={newCompetitionUrl}
          />
          <IconLink
            id="search-competitions"
            icon={AppIcons.Search}
            onClick={() => searchCard.setVisible(true)}
            hidden={searchCard?.visible}
          />
        </div>
      </div>

      {searchCard.card}

      <CompetitionTable
        lang={props.lang}
        competitions={props.values ?? []}
        noDataMessage={t("NoCompetitionsWereFound")}
        timespanColumnLabel={t("Timespan")}
        nameColumnLabel={t("Name")}
        isLoading={controller.isLoading}
      />
      {controller.isCreateCompetitionEnabled &&
        <div className="row justify-content-end">
          <div className="col-md-3 col-12 d-grid">
            <LinkButton
              hidden={!controller.isCreateCompetitionEnabled}
              id="new-competition"
              icon={AppIcons.CreateCompetition}
              href={newCompetitionUrl}
              style="subtle"
            >
              <Trans i18nKey="CreateCompetition" t={t}/>
            </LinkButton>
          </div>
        </div>
      }
    </div>
  );
}
