import { OrganizationModel } from "./OrganizationModel";

export module OrganizationSelectionRepository {
  const STORAGE_KEY = "selectedOrganizationId";

  export function saveSelectedOrganization(
    organization: OrganizationModel | undefined
  ): void {
    "use client";
    window.sessionStorage.removeItem(STORAGE_KEY);
    if (typeof window !== undefined) {
      if (organization !== undefined) {
        window.sessionStorage.setItem(
          STORAGE_KEY,
          JSON.stringify(organization)
        );
      }
    }
  }

  function getSelectedOrganizationId(): string | undefined {
    "use client";
    let organizationId = undefined;
    const organization = getSelectedOrganization();
    if (organization !== null) {
      organizationId = organization.id;
    }
    return organizationId;
  }

  function getSelectedOrganizationName(): string | undefined {
    "use client";
    let organizationName = undefined;
    const organization = getSelectedOrganization();
    if (organization !== null) {
      organizationName = organization.name;
    }
    return organizationName;
  }

  export function getSelectedOrganization(): OrganizationModel | null {
    "use client";
    let organization = null;
    if (typeof window !== undefined) {
      const organizationJson = window.sessionStorage.getItem(
        STORAGE_KEY
      ) as string;

      if (organizationJson !== null) {
        try {
          organization = JSON.parse(organizationJson) as OrganizationModel;
        } catch (error) {
          window.sessionStorage.removeItem(STORAGE_KEY);
        }
      }
    }
    return organization;
  }
}
