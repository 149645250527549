"use client";

import "./LabeledCard.css";

import { Card, CardHeader } from "react-bootstrap";
import IconLink from "@/components/common/IconLink/IconLink";
import { AppIcons } from "@/components/global/icons/AppIcons";
import { useState } from "react";
import Link from "next/link";

export type CardProperties = {
  card: JSX.Element;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}
type Props = {
  id: string;
  title: string;
  href?: string;
  closeable?: boolean;
  initialVisible?: boolean;
  children: React.ReactNode;
}
export default function useCard(props: Readonly<Props>): CardProperties {
  const [showCard, setShowCard] = useState(props.initialVisible === undefined ? true : props.initialVisible);

  const closeable = props.closeable === undefined ? true : props.closeable;

  const card = (
    <LabeledCard {...props} visible={showCard} closeAction={closeable ? () => setShowCard(false) : () => {}}>
      {props.children}
    </LabeledCard>
  );

  return {
    card,
    visible: closeable ? showCard : true,
    setVisible: closeable ? setShowCard : () => {}
  };
}

type UIProps = {
  visible: boolean;
  closeAction: () => void;
} & Props;

export function LabeledCard(props: Readonly<UIProps>) {
  return (
    <Card className="labeled-card" hidden={!props.visible} id={props.id}>
      <CardHeader>
        <div className="row">
          <div className="col-11">
            {props.href ? <Link href={props.href}>{props.title}</Link> : <span>{props.title}</span>}
          </div>
          <div className="col-1 icon-container" hidden={props.closeable == false}>
            <IconLink
              id={props.id + "-close"}
              variant="secondary"
              icon={AppIcons.Cancel}
              onClick={props.closeAction}
            />
          </div>
        </div>
      </CardHeader>
      <div className="container mw-100">
        {props.children}
      </div>
    </Card>
  );
}